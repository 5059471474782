import React from 'react'
import styled from 'styled-components';
import Arrow from '../../assets/button-arrow.inline.svg'


const ButtonStyled = styled.div`
position:relative;
z-index:10;
display: inline-flex;
width: auto;
flex-wrap:wrap;

    div{
    padding:10px 15px;
    margin:0;
    font-family:var(--font-reg);
    transition:0.5s all ease-in-out;
    border:1px solid var(--${(props) => props.buttonBorderColor});
    background-color:var(--${(props) => props.buttonBgColor});
    color:var(--${(props) => props.buttonTextColor});
    overflow:hidden;
    position: relative;

        &:after{
        content:"";
        position:absolute;
        top:0px;
        /* top:-10px; */
        left:0;
        width:100%;
        height:100%;
        /* height:calc(100% + 20px); */
        transform:translateX(-101%);
        background:var(--${(props) => props.buttonBgColorHover});
        transition:0.5s all ease-in-out;
        z-index:0;
        }

        input{
        width:100%;
        height:100%;
        position: absolute;
        top:0;
        left:0;
        opacity:0;
        z-index:1;
        cursor: pointer;
        }

        span{
        z-index:1;
        position: relative;
        pointer-events:none;
        }

        svg{
        margin-left: 2rem;
        
            path{
            transition:0.5s all ease-in-out;
            fill:var(--${(props) => props.color});
            }

        }

        &:hover{
        color:var(--${(props) => props.buttonTextColorHover});
        border:1px solid var(--${(props) => props.buttonBorderColor});

            svg path{
                fill:var(--${(props) => props.buttonTextColorHover});
            }
            &:after{
                transform:translateX(0%);
            }
        }
    }

   
  
`

export default function ButtonForm(data) {
    return (
        <ButtonStyled 
            buttonBgColor={data.buttonBgColor}
            buttonBgColorHover={data.buttonBgColorHover}
            buttonTextColor={data.buttonTextColor}
            buttonTextColorHover={data.buttonTextColorHover}
            buttonBorderColor={data.buttonBorderColor}
            >
                <div>
                <span>
                    {data.text}
                    <Arrow />
                </span>
                <input type="submit" />
                </div>
            </ButtonStyled> 
    )
}
