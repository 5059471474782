const getListingSlug = function (listing) {
  let slug
  if (listing.listingRaw) {
    if (
      listing.listingRaw.listingsTagLocation.nodes[0].wpParent.node.slug ===
      process.env.GATSBY_SUBDOMAIN
    ) {
      slug = `/areas/${process.env.GATSBY_SUBDOMAIN}/${listing.listingRaw.slug}`
    }
    if (
      listing.listingRaw.listingsTagLocation.nodes[0].wpParent.node.slug ===
      "other-areas"
    ) {
      slug = `/areas/${listing.listingRaw.listingsTagLocation.nodes[0].slug}/${listing.slug}`
    }
    // console.log(
    //   listing.listingRaw.listingsTagLocation.nodes[0].wpParent.node.slug
    // )
  } else {
    if (
      listing.listingsTagLocation.nodes[0].wpParent.node.slug ===
      process.env.GATSBY_SUBDOMAIN
    ) {
      slug = `/areas/${process.env.GATSBY_SUBDOMAIN}/${listing.slug}`
    }
    if (
      listing.listingsTagLocation.nodes[0].wpParent.node.slug === "other-areas"
    ) {
      slug = `/areas/${listing.listingsTagLocation.nodes[0].slug}/${listing.slug}`
    }
  }
  console.log(slug)
  return slug
}

export default getListingSlug
