import styled, { css } from "styled-components"
import SearchBox from "./search-box"

const open = css`
  width: 10em;
  background: ${({ theme }) => theme.background};
  cursor: text;
  margin-left: -1.6em;
  padding-left: 1.6em;
`

const closed = css`
  width: 0;
  background: transparent;
  cursor: pointer;
  margin-left: -1em;
  padding-left: 1em;
`

export default styled(SearchBox)`
  /* display: flex;
  flex-direction: row-reverse;
  align-items: center; */
  margin-bottom: 0;
  width:100%;

  .SearchInput {
    outline: none;
    border:1px solid var(--black);
    font-size: 1em;
    width:100%;
    transition: 100ms;
    border-radius: 0;
    position:relative;
    /* color: ${({ theme }) => theme.foreground}; */
    /* ::placeholder {
      color: ${({ theme }) => theme.faded};
    } */
    /* ${({ hasFocus }) => (hasFocus ? open : closed)} */

    
    
    padding:1rem 2.5rem;
    

  }

  .SearchIcon {
    width: 25px;
    height: 25px;
    margin: 0;
    color: ${({ theme }) => theme.foreground};
    pointer-events: none;
    top: 13px;
    left: 7px;
    position:absolute;
  }
`