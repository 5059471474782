import { Link } from "gatsby"
import { default as React } from "react"
import getListingSlug from "../../utils/getListingSlug"
import {
  connectStateResults,
  Highlight,
  Hits,
  Index,
  Snippet,
  PoweredBy,
} from "react-instantsearch-dom"

const HitCount = connectStateResults(({ searchResults, title }) => {
  const hitCount = searchResults && searchResults.nbHits

  return hitCount > 0 ? (
    <div className="HitCount">
      <div className="HitCountInner">
        <h3 className="search-results-title">{title}</h3>
        {hitCount} result{hitCount !== 1 ? `s` : ``}
      </div>
    </div>
  ) : null
})

const PageHit = ({ hit }) => (
  <div>
    {"acf_imported_listings" in hit ? (
      <a
        href={`https://${hit.acf_imported_listings.parentCity}.independentlife.co.uk/independents/${hit.acf_imported_listings.slug}/`}
      >
        <h4>{hit.title}</h4>
      </a>
    ) : "categories" in hit && hit.categories.nodes[0] !== undefined ? (
      <Link to={`/${hit.categories.nodes[0].slug}/${hit.slug}/`}>
        <h4>{hit.title}</h4>
      </Link>
    ) : (
      <Link to={getListingSlug(hit)}>
        <h4>{hit.title}</h4>
        {console.log(hit)}
      </Link>
    )}
  </div>
)

const setSectionTitle = index => {
  let sectionTitle
  if (index.name.includes("Posts index")) {
    sectionTitle = "Articles"
  } else if (index.name.includes("Imported Listings")) {
    sectionTitle = "Independents from other cities"
  } else {
    sectionTitle = `Local Independents`
  }

  return sectionTitle
}

const HitsInIndex = ({ index }) => (
  <Index indexName={index.name}>
    <HitCount title={setSectionTitle(index)} />
    <Hits className="Hits" hitComponent={PageHit} />
  </Index>
)

const SearchResult = ({ indices, className, showMessage }) => (
  <div className={className}>
    {showMessage ? (
      <h1 className="instructionMessage">Start typing to search our content</h1>
    ) : (
      ""
    )}
    {indices.map(index => (
      <HitsInIndex index={index} key={index.name} />
    ))}
    {/* <PoweredBy /> */}
  </div>
)

export default SearchResult
