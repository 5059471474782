import React from 'react'
import CountUp from 'react-countup';
import { useInView } from 'react-hook-inview'

export default function Stat(props) {
    const [ref, isVisible] = useInView({
        threshold: 1,
        unobserveOnEnter: true
      })
    return (
        <CountUp 
        start={isVisible ? parseInt(props.start) : ''} 
        end={isVisible ? parseInt(props.end) : ''} 
        prefix={props.prefix} 
        suffix={props.suffix} duration={5}
        useEasing={true}
        >
        {({ countUpRef, start }) => (
            <div ref={ref} className="stat">
                <span start={isVisible ? 'start' : ''} ref={countUpRef} />
            </div>
        )}
        </CountUp>
    )
}
