import React, { Component } from 'react';
import styled, { css } from "styled-components"
import { InstantSearch, Hits, connectSearchBox } from 'react-instantsearch-dom';
import {IoSearchSharp} from 'react-icons/io5'

const open = css`
  width: 10em;
  background: ${({ theme }) => theme.background};
  cursor: text;
  margin-left: -1.6em;
  padding-left: 1.6em;
`

const closed = css`
  width: 0;
  background: transparent;
  cursor: pointer;
  margin-left: -1em;
  padding-left: 1em;
`

const SearchBoxStyled = styled.form`
  margin-bottom: 0;
  width:100%;

  .SearchInput {
    outline: none;
    border:1px solid var(--black);
    font-size: 1em;
    width:100%;
    transition: 100ms;
    border-radius: 0;
    position:relative;
    /* color: ${({ theme }) => theme.foreground}; */
    /* ::placeholder {
      color: ${({ theme }) => theme.faded};
    } */
    /* ${({ hasFocus }) => (hasFocus ? open : closed)} */

    
    
    padding:1rem 2.5rem;
    

  }

  .SearchIcon {
    width: 25px;
    height: 25px;
    margin: 0;
    color: ${({ theme }) => theme.foreground};
    pointer-events: none;
    top: 13px;
    left: 7px;
    position:absolute;
  }
`

class SearchBox extends Component {
  timerId = null;

  state = {
    value: this.props.currentRefinement
  };

  onChangeDebounced = event => {
    const { refine, delay } = this.props;
    const value = event.currentTarget.value;
    this.props.setFocus(true);

    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => refine(value), delay);

    this.setState(() => ({
      value
    }));
  };

  render() {
    const { value } = this.state;

    return (
      <SearchBoxStyled>
        <input
          className="SearchInput"
          placeholder="Search"
          aria-label="Search"
          value={value}
          // onFocus={this.onFocus}
          onChange={this.onChangeDebounced}
          placeholder="Search for products..."
        />
        <IoSearchSharp className="SearchIcon" />
      </SearchBoxStyled>
    );
  }
}



const DebouncedSearchBox = connectSearchBox(SearchBox);

export default DebouncedSearchBox