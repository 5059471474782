import styled, { css } from "styled-components"
import SearchResult from "./search-result"

const Popover = css`
  height:100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  padding-bottom:4rem;
  overflow:scroll;
`

export default styled(SearchResult)`
  transition:0.5s all ease-in-out;
  /* transform:${props => (props.show ? `translateX(0)` : `translateX(100%)`)}; */

  ${Popover}

  .HitCount {
    display: flex;
    width:calc(100% + 2rem);
    margin-left:-1rem;
    background:var(--brandColor);
    color:var(--black);
    padding:1rem 2rem;
    margin-bottom:1rem;
    justify-content: flex-start;

    .HitCountInner {
    display: flex;
    width:100%;
    justify-content: space-between;    
    }

  }

  .Hits {
    ul {
      list-style: none;
      margin-left: 0;
    }

    li.ais-Hits-item {
      margin-bottom: 1em;
      padding:0 1rem;

      a {
        color:var(--brandColor);

        h4 {
          margin-bottom: 0.2em;
        }
      }
    }
  }

  .ais-PoweredBy {
    display: flex;
    justify-content: flex-end;
    font-size: 80%;

    svg {
      width: 70px;
    }
  }
`