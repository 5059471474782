import React from 'react'
import LogoMark from '../../assets/logomarkFooter.inline.svg'
import Nav from './Nav';
import styled from 'styled-components';
import Mailchimp from './Mailchimp';
import {AiFillFacebook} from 'react-icons/ai'
import {AiOutlineTwitter} from 'react-icons/ai'
import {AiOutlineInstagram} from 'react-icons/ai'
import {AiFillYoutube} from 'react-icons/ai'
import parse from 'html-react-parser'
import { StaticQuery, graphql, Link } from "gatsby"
import Stat from './Stat';
import Button from './Button'
                                
const FooterWrap = styled.footer`
background:var(--grey);
display:flex;
position: relative;
justify-content:space-between;
font-family:var(--font-reg);
transition:0.5s transform ease-in-out;
transform:translateX(${props => props.citySwitcherActive});
padding:2rem ;
flex-wrap:wrap;

    @media only screen and (min-width: 1024px) {
    padding:8rem 4rem;
    flex-wrap:no-wrap;
    }

    .col-1{
    margin-bottom:2rem;
    padding-bottom:2rem;
    border-bottom:1px solid var(--charcoal);
    width:100%;

        @media only screen and (min-width: 750px) {
        width:50%;
        }

        @media only screen and (min-width: 1024px) {
        margin-bottom:0;
        padding-bottom:0;
        border-bottom:0;
        width:auto;
        }

        .top{
        width:100%;
        display:flex;
        margin-bottom:2rem;

            .svg-wrap{
            width:80px;
            height:80px;
            background:var(--black);
            padding:1rem;
            display:flex;
            justify-content:center;
            align-items:center;
            margin-right:2rem;

                svg{
                width:100%;
                height:100%;

                    #Fill-13,
                    #Fill-33{
                        fill:var(--${process.env.GATSBY_BRANDCOLOR})
                    }

                }
                

            }

            ul{
            display:flex;
            list-style:none;

                li{
                margin-right:1rem;
                    
                    a{
                    color:var(--black);
                    text-decoration:none;
                    
                        svg{
                        width:23px;
                        height:23px;
                        transition:0.5s all ease-in-out;
                        position: relative;

                            path{
                            transition:0.5s all ease-in-out;
                            }
                        }

                        &:hover{
                            
                            svg{
                            transform:scale(1.2);

                                path{
                                fill:var(--red)
                                }
                            }
                        }
                    }
                }
            }
        }

        .bottom{
            
        
            .heading{
            font-size:1.4rem;
            margin-bottom:1rem;
            }

            ul{
            list-style:none;
            margin-bottom:2rem;

                li{
                margin-bottom:1rem;
                display:flex;
                align-items:center;

                    &:last-of-type{
                    margin-bottom:0;
                    }

                    .stat{
                    color:var(--red);
                    font-family:var(--font-heading);
                    font-size:3rem;
                    margin-right:0.8rem;
                    }

                    .label{
                    font-size:0.9rem;
                    max-width: 101px;
                    }

                }

            }
        }
    }

    .col-2{
    font-size:0.9rem;
    margin-bottom:2rem;
    padding-bottom:2rem;
    border-bottom:1px solid var(--charcoal);
    width:100%;
        
        @media only screen and (min-width: 750px) {
        width:50%;
        }

        @media only screen and (min-width: 1024px) {
        margin-bottom:0;
        padding-bottom:0;
        border-bottom:0;
        width:auto;
        }


        .nav-lower{    
        list-style:none;
        margin-bottom:4rem;
            
            li{
            margin-bottom:0.75rem;

                &:last-child{
                margin-bottom:0;
                }

                button,
                a{
                text-decoration:none;
                background:none;
                border:0;
                cursor: pointer;
                color:var(--black);
                transition:0.5s all ease-in-out;

                    &:hover{
                    color:var(--red);
                    }

                }
            }
        }

        .contact{
        margin-bottom:4rem;

            .phone{
                margin-bottom:0.75rem;
            }


            .phone,
            .email{
                a{
                text-decoration:none;
                color:var(--black);
                font-family:var(--font-bold);
                transition:0.5s all ease-in-out;

                    &:hover{
                    color:var(--red);
                    }

                }
            }

        }
        
        .address{
        max-width:160px;

            p{
            line-height:1.5rem;
            margin-bottom:1rem;

                &:last-child{
                margin-bottom:0;
                }

            }

            a{
            text-decoration:none;
            color:var(--black);
            transition:0.5s all ease-in-out;

                &:hover{
                color:var(--red);
                }

            }
            }
        }
    }

    .col-3{
        .title{
            font-size:1.4rem;
            margin-bottom:1rem; 
        }
        .text{
            font-size:0.9rem;
            margin-bottom:1rem; 
        }
    }

`

const Credits = styled.div`
width:100%;
margin-top:2rem;
font-size:0.65rem;
padding-top:2rem;
border-top:1px solid var(--black);

    @media only screen and (min-width: 1024px) {
        margin-top:0rem;
        text-align:right;
        padding-top:0;
        border-top:0;
    }

    a{
    text-decoration:none;
    color:var(--black);
    transition:0.5s all ease-in-out;

        &:hover{
        color:var(--red);
        }

    }

`


export default function Footer(props) {
    
    return (
        <StaticQuery
          query={graphql`
            query FooterQuery {
                wp {
                    options {
                      acf_options {
                        address
                        facebook
                        googleMapDirections
                        instagram
                        phone
                        phoneFormatted
                        twitter
                        youtube
                        stats {
                          label
                          stat
                          statSuffix
                          icon
                        }
                      }
                    }
                }
            }
          `}
          render={data => (
            <FooterWrap  citySwitcherActive={props.translateContent} >
                
           <div className="col col-1">
            <div className="top">
                <div className="svg-wrap">
                    <LogoMark />
                </div>
                <ul>
                    <li><a href={data.wp.options.acf_options.twitter} target="_blank" rel="noreferrer noopener" aria-label="Independent Life Twitter"><AiOutlineTwitter /></a></li>
                    <li><a href={data.wp.options.acf_options.facebook} target="_blank" rel="noreferrer noopener" aria-label="Independent Life Facebook"><AiFillFacebook /></a></li>
                    <li><a href={data.wp.options.acf_options.instagram} target="_blank" rel="noreferrer noopener" aria-label="Independent Life Instagram"><AiOutlineInstagram /></a></li>
                    {/* <li><a href="https://www.youtube.com/channel/UCO1NJazYXCoB0o5L-GT6n2Q" target="_blank" rel="noreferrer noopener" aria-label="Independent Life Youtube"><AiFillYoutube /></a></li> */}
                </ul>
            </div>
            <div className="bottom">
                <div className="heading">Our vitals...</div>
                <ul>
                    {
                        data.wp.options.acf_options.stats.map((stat) => {
                            return(
                                <li>
                                    <Stat 
                                    start={0}
                                    end={stat.stat}
                                    prefix={''}
                                    suffix={stat.statSuffix}
                                    />
                                    <div className="label">{stat.label}</div>
                                </li>
                            )
                        })
                    }
                    
                </ul>
                <Button
                    color="black"
                    bg="black"
                    text={'Advertise with us'}
                    link={'/advertise'}
                />
            </div>
           </div>
           <div className="col col-2">
                <Nav toggleMobMenu={function(){}} className="footer-nav" />
                <div className="contact">
                    <div className="phone">T: <a href={`tel:${data.wp.options.acf_options.phoneFormatted}`}>{data.wp.options.acf_options.phone}</a></div>
                    <div className="email">E: <a href="mailto:&#104;&#101;&#108;&#108;&#111;&#064;&#105;&#110;&#100;&#101;&#112;&#101;&#110;&#100;&#101;&#110;&#116;&#108;&#105;&#102;&#101;&#046;&#099;&#111;&#046;&#117;&#107;">&#104;&#101;&#108;&#108;&#111;&#064;&#105;&#110;&#100;&#101;&#112;&#101;&#110;&#100;&#101;&#110;&#116;&#108;&#105;&#102;&#101;&#046;&#099;&#111;&#046;&#117;&#107;</a></div>
                </div>
                <div className="address">
                    {parse(data.wp.options.acf_options.address)}
                </div>
           </div>
           <div className="col col-3">
                <div className="title">So you'll always be the first to know</div>
                <div className="text">Sign up to the Independent Life Newsletter</div>
                <Mailchimp 
                    formFieldBgColor="grey"
                    buttonBgColor="grey" 
                    buttonBgColorHover="black" 
                    buttonTextColor="black" 
                    buttonTextColorHover="white" 
                    buttonBorderColor="black"
                    buttonBorderColorHover="black"
                />
           </div>
            <Credits>
                &copy; Independent City Ltd.  | Website: <a href="https://builtbymike.co.uk" target="_blank" rel="noindex norefferer">Built by Mike</a> 
            </Credits>
        </FooterWrap>
          )}
        />
      )
}
