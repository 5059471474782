import { createGlobalStyle } from "styled-components"

export const Typography = createGlobalStyle`

html{
	font-family:var(--font-reg);
}

.heading--xl{
	font-family:var(--font-heading);
	text-transform:uppercase;
    font-size: 2.5rem;
	
    @media only screen and (min-width: 750px) {
        font-size: 3rem;
    }
    
    @media only screen and (min-width: 1100px) {
        font-size: 3.5rem;
    }
}

.heading--l{
	font-family:var(--font-heading);
    text-transform:uppercase;
    font-size: 2rem;
	
    @media only screen and (min-width: 750px) {
        font-size: 2.5rem;
    }
    
    @media only screen and (min-width: 1100px) {
        font-size: 3rem;	
    }
}

.heading--m{
	font-family:var(--font-heading);
    text-transform:uppercase;
    font-size:2.5rem;
}

.heading--s{
	font-family:var(--font-heading);
    text-transform:uppercase;
    font-size:1.5rem;
}



`
