import React, {createRef, Component } from 'react'
import styled from 'styled-components'
import parse from 'html-react-parser'
import {GatsbyImage} from 'gatsby-plugin-image'
import Mailchimp from './Mailchimp';
import {CgClose} from 'react-icons/cg'
import { StaticQuery, graphql } from "gatsby"

const Modal = styled.div`
display:${props => props.display === 0 ? 'none' : 'flex'} !important;
opacity:${props => props.hideModal === 0 ? "0" : "1"} !important;
pointer-events:${props => props.hideModal === 0 ? 'none' : 'all'} !important;

  .centered{
  transform:${props => props.hideModal === 0 ? "translateY(200px)" : "translateY(0)"};
    
    .heading--l{
    color:var(--brandColor);
    }

    .right{
      form{
        .email{
        margin-right:2rem;
        }
      }
    }
  }
`






export default class SignupModal extends Component {

  constructor(props){
    super(props);
    this.state = {
    hideModal: 1,
    display:0
    }    
    this.modal = createRef();
  }  
  
  setHideSignupModalCookie = () => {
    let seconds = 60;
    let minutes = 60;
    let hours = 24;
    let days = 3;
    let expires =  seconds * minutes * hours * days;
    document.cookie = `hideSignup=true; max-age=${expires}; path=/;`;
    this.setState({
      hideModal: 0
    })
    
  }

  hasCookie = () => {
    
    if(!document.cookie.match(/^(.*;)?\s*hideSignup\s*=\s*[^;]+(.*)?$/)){
      this.setState({
        display:1
      })
    }
  }

  componentDidMount(){
    this.hasCookie();
    this.setOverflowOnModal();
    
  }

  componentDidUpdate(){
    this.setOverflowOnModal();
  }

  componentWillUnmount(){

  }

  setOverflowOnModal = () => {
    const outerH = this.modal.current.offsetHeight;
    const innerH = this.modal.current.querySelector('.centered .right').offsetHeight;
  }

  render() {
    return (
      <StaticQuery
      query={graphql`
        query SignUpModalQuery {
            wp {
                options {
                  acf_options {
                    signupText
                    signupHeading
                    signupImage {
                      localFile{
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                  }
                }
            }
        }
      `}
      render={data => (
        <Modal className="modal" ref={this.modal} hideModal={this.state.hideModal} display={this.state.display === 0 ? 0 : 1}>
            <div className="centered">
              <div className="left">
                <GatsbyImage image={data.wp.options.acf_options.signupImage.localFile.childImageSharp.gatsbyImageData} />
              </div>
              <div className="right">
                <button className="close" aria-label="Close Popup" onClick={this.setHideSignupModalCookie}><CgClose /></button>
                <h1 className="heading--l">{parse(data.wp.options.acf_options.signupHeading)}</h1>
                <div className="text">{parse(data.wp.options.acf_options.signupText)}</div>
                <Mailchimp 
                    formFieldBgColor="white"
                    buttonBgColor="brandColor" 
                    buttonBgColorHover="black" 
                    buttonTextColor="black" 
                    buttonTextColorHover="brandColor" 
                    buttonBorderColor="black"
                    buttonBorderColorHover="brandColor"
                    hideModal={this.setHideSignupModalCookie}
                />
              </div>
            </div>  
        </Modal>
      )}
    />
    )
  }
}
