import React, {createRef} from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp'
import styled from 'styled-components';
import ButtonForm from './ButtonForm';

const Error = styled.div`
color:red;
margin-bottom:2rem;
line-height:1.5rem;
font-size:0.9rem;

    a{
    color:red;
    font-family:var(--font-bold)
    }

`

const Form = styled.form`

    input[type="email"]{
    width:100%;
    padding:0.7rem 0.5rem 0.5rem 0.5rem;
    margin-bottom:1rem;
    background-color:var(--${props => props.formFieldBgColor});
    border:1px solid var(--black);
    font-size:0.9rem;

        &:focus{
        outline:none;
        }
        
        &::placeholder{
        font-size:0.9rem;
        color:var(--black);
        }

    }

`

export default class Mailchimp extends React.Component {
  // Since `addToMailchimp` returns a promise, you
  // can handle the response in two different ways:

  // Note that you need to send an email & optionally, listFields
  // these values can be pulled from React state, form fields,
  // or wherever.  (Personally, I recommend storing in state).

    constructor(props){
        super(props)
        this.state = {
            email: null,
        }
        this.errorMessage = createRef();
    }

    _handleChange = e => {
        this.setState({
            [`${e.target.name}`]: e.target.value,
        })
    }

    _handleSubmit = e => {
        e.preventDefault()

        addToMailchimp(this.state.email, this.state)
            .then(({ msg, result }) => {
                if (result !== 'success') {
                    throw msg
                }
                this.errorMessage.current.innerHTML = msg
                // this.props.hideModal();
            })
            .catch(err => {
                this.errorMessage.current.innerHTML = err
            })
            
    }

  render () {
    return (
        <Form 
        onSubmit={this._handleSubmit} 
        formFieldBgColor={this.props.formFieldBgColor}
        >   
            <Error ref={this.errorMessage} className="mailchimp-error"></Error>
            <input
                type="email"
                placeholder="Enter your email"
                onChange={this._handleChange}
                name="email"
            />

            <ButtonForm 
            buttonBgColor={this.props.buttonBgColor}
            buttonBgColorHover={this.props.buttonBgColorHover}
            buttonTextColor={this.props.buttonTextColor}
            buttonTextColorHover={this.props.buttonTextColorHover}
            buttonBorderColor={this.props.buttonBorderColor}
            text="Sign up"
            />
        </Form>
    )
  }
}
