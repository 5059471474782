import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Arrow from "../../assets/button-arrow.inline.svg"

const ButtonStyled = styled.div`
  position: relative;
  z-index: 10;
  display: inline-flex;
  width: auto;
  flex-wrap: wrap;

  a {
    text-decoration: none;
    padding: 10px 15px;
    margin: 0;
    font-family: var(--font-reg);
    transition: 0.5s all ease-in-out;
    border: 1px solid var(${props => props.color});
    color: var(${props => props.color});
    overflow: hidden;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 0px;
      /* top:-10px; */
      left: 0;
      width: 100%;
      height: 100%;
      /* height:calc(100% + 20px); */
      transform: translateX(-100%);
      background: var(${props => props.bg});
      transition: 0.5s all ease-in-out;
      z-index: -1;
    }

    span {
      z-index: 1;
      position: relative;
    }

    svg {
      margin-left: 2rem;

      path {
        transition: 0.5s all ease-in-out;
        fill: var(${props => props.color});
      }
    }

    &:hover {
      color: var(${props => props.invert});
      border: 1px solid var(${props => props.bg});

      svg path {
        fill: var(${props => props.invert});
      }
      &:after {
        transform: translateX(0%);
      }
    }
  }
`

export default function Button(data) {
  return (
    <ButtonStyled
      color={"--" + data.color}
      bg={data.bg ? "--" + data.bg : "--white"}
      invert={data.color === "white" ? "--black" : "--white"}
    >
      <Link
        to={data.prefix ? `${data.prefix}/${data.link}` : data.link}
        className={`button ${data.customClass}`}
      >
        {data.text}
        <Arrow />
      </Link>
    </ButtonStyled>
  )
}
