import { createGlobalStyle } from "styled-components"
import CloseLeeds from "../images/CloseLeeds.svg"
import CloseYork from "../images/CloseYork.svg"
import CloseManchester from "../images/CloseManchester.svg"
import CloseProperty from "../images/CloseProperty.svg"

export const GlobalStyle = createGlobalStyle`

:root{
    --yellow: #FBC900;
    --orange: #F59E08;
    --red: #DA3649;
    --black: #000000;
    --white: #ffffff;
    --grey: #D9D9D8;
    --charcoal: #1D252B;
    --teal: #778C8C;

    --font-heading:'Conv_Resolve-ExtraboldCn';
    --font-reg:'Conv_AvenirLTStd-Book';
    --font-med:'Conv_AvenirLTStd-Medium';
    --font-bold:'Conv_AvenirLTStd-Heavy';
    --font-brand:'Conv_ReneBieder-GalanoGrotesqueSemiBold';

    --trans: '0.5s all ease-in-out';

    --headerHeight:136px;
    --headerHeightSetter:136px;

    --brandColor: var(--${process.env.GATSBY_BRANDCOLOR});
    --brandColorSecondary: var(--${process.env.GATSBY_BRANDCOLORSECONDARY});

    --leeds: var(--yellow);
    --york: var(--orange);
    --manchester: var(--red);

}

*{
box-sizing: border-box;
margin: 0;
padding: 0;
line-height: 1;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}


html, body {touch-action: none !important;}

html{
background:var(---charcoal);
}

.wrapper{
max-width: 1200px;
padding:0 2rem;
margin:0 auto;
position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6{
margin-top:0;
}

ul{
padding:0;
margin:0;
}

.gm-style .gm-style-iw-c{
padding:0 !important;
border-radius: 0 !important;
background-color:var(--grey);
overflow: visible !important;
}

.gm-style .gm-style-iw-t::after{
display: none !important;
    /* background: linear-gradient(
        45deg
        ,rgba(0,0,0,1) 50%,rgba(255,255,255,0) 51%,rgba(255,255,255,0) 100%); */
}

.gm-style-iw-d{
overflow: visible !important;
}


.hide .cluster{
display:none !important;
}


button{
border:inherit;
background:inherit;
border-radius: 0;
color: inherit;
}

button:focus{
outline:none;
}


select{
    -webkit-appearance:none;
    color: inherit;
}


*{
    font-weight: normal;
    font-style: normal; 
    overscroll-behavior: none;
}

*:focus{
/* outline:none !important; */
}

.slick-parent .slick-current{
z-index: 100 !important;
}

.snipcart-layout{
transition:0.5s all ease-in-out !important;
min-height:calc(100vh - var(--headerHeightSetter)) !important;
min-height: calc((var(--vh, 1vh) * 100) - var(--headerHeightSetter) ) !important;
height:calc(100vh - var(--headerHeightSetter)) !important;
height: calc((var(--vh, 1vh) * 100) - var(--headerHeightSetter) ) !important;
margin-top:var(--headerHeightSetter) !important;

    .snipcart-cart-button--highlight{
    background:var(--brandColor) !important;   
    transition:0.5s all ease-in-out !important;
    color:var(--black) !important;

        &:hover{
        background:var(--black) !important;
        color:var(--white) !important;
        }

    }

    a,
    .snipcart__actions--link{
    color:var(--brandColor) !important;
    transition:0.5s all ease-in-out !important;

        &:hover{
            color:var(--black) !important;
        }
    }

    .snipcart__box--badge-highlight{
        background:var(--brandColor) !important;      
    }

    .snipcart-checkbox:checked+label:before{
    border: 1px solid var(--brandColor) !important; 
    background-color: var(--brandColor) !important; 
    }
    .snipcart-payment-form--focused,
    .snipcart-textbox--focus,
    .snipcart-input:focus-within{
        border: 1px solid var(--brandColor) !important; 
    }

    .snipcart__button--icon path,
    .snipcart__icon--blue-light path,
    .snipcart__icon--blue-dark path {
    fill: var(--brandColor) !important; 
    }

    .snipcart-shipping-rates-list-item--highlight{
        border: 1px solid var(--brandColor) !important;   
    }

    .snipcart-form-radio:checked+label:before{
        background: var(--brandColor) !important; 
    }

    .snipcart-terms{
    margin-left:10px;
    }

    .snipcart-form__label{
    white-space: normal;
    line-height: 1.5rem;
    
        strong{
        display: inline-block;
        font-weight: bold;
        }

    }

}

.cluster{
    img{
    width:100%;
    height:100%;
    }

    div{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    }
}

button.gm-ui-hover-effect {
opacity:1 !important;

    &:before{
    background-image:url(${
      process.env.GATSBY_SITENAME === "Leeds"
        ? CloseLeeds
        : process.env.GATSBY_SITENAME === "York"
        ? CloseYork
        : process.env.GATSBY_SITENAME === "Leeds"
        ? CloseManchester
        : " "
    });
    background-size: contain !important;
    content: "";
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    position: absolute;
    background-repeat: no-repeat;
    }   
    
    img{
    visibility:hidden !important;
    }

    span{
    opacity: 0 !important;
    }
}

button.gm-control-active{
background:var(--brandColor) !important;
transition:0.5s all ease-in-out !important;

    &:hover{
    filter:contrast(2.5) !important;
    }

    > img{
    filter: brightness(0.5) !important;
    }

}

.properties-map button.gm-ui-hover-effect {
    &:before{
    background-image:url(${CloseProperty});
    }
}

#nprogress .bar{
height:6px !important;
}


input{
--webkit-appearance:none;
border-radius: 0;
}

a[href^="tel:"] {
  color:inherit; 
  text-decoration:none; 
}

.mailchimp-error{
max-width:350px;
}

`
