import React, {createRef}  from "react"
import firebase from "gatsby-plugin-firebase"
import styled from 'styled-components';
import parse from 'html-react-parser'
// import {GatsbyImage} from 'gatsby-plugin-image'
import {GatsbyImage} from 'gatsby-plugin-image'
import ButtonForm from './global/ButtonForm';
import {CgClose} from 'react-icons/cg'
import { handleLogin } from "../services/auth"
import { StaticQuery, graphql } from "gatsby"

const LoginForm = styled.div`
opacity:${props => props.showModal === false ? "0" : "1"};
pointer-events:${props => props.showModal === false ? 'none' : 'all'};;

  .centered{
  opacity:${props => props.showModal === false ? "0" : "1"};
  transform:${props => props.showModal === false ? "translateY(200px)" : "translateY(0px)"};
  }
`

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.loginForm = createRef();
  }

  state = {
    email: ``,
    password: ``,
    modal: this.props.isActive,
  }

  componentDidMount(){
    const isClient = typeof document !== 'undefined' && typeof window !== 'undefined';
    let header

    if(isClient){
      header = document.querySelector('#header');
      if(header){header.parentNode.after(this.loginForm.current)}
    }
    
  }

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  toggleLoginModalAndControl = () => {
    this.props.toggleLoginModal();
    this.props.toggleIsLoggedIn();
    handleLogin(this.state)
  }
  toggleModal = () => {
    this.props.toggleLoginModal();
  }
  
  handleSubmit = event => {
    event.preventDefault()
    
    firebase.auth().signInWithEmailAndPassword(this.state.email,this.state.password).then(
      this.toggleLoginModalAndControl
    ).catch(
      function(error) {
        var errorCode = error.code;
        var errorMessage = error.message;

        if (errorCode === 'auth/wrong-password') {
          alert('Wrong password.');
        } else {
          alert(errorMessage);         
        }
      }
    );
  }

  render() {
    return (
      <StaticQuery
          query={graphql`
            query LoginModalQuery {
                wp {
                    options {
                      acf_options {
                        loginText
                        loginHeading
                        loginImage {
                          localFile{
                            childImageSharp {
                              gatsbyImageData
                            }
                          }
                        }
                      }
                    }
                }
            }
          `}
          render={data => (
            <LoginForm className="modal" ref={this.loginForm} showModal={this.props.isActive}>
              <div className="centered">
                <div className="left">
                  <GatsbyImage image={data.wp.options.acf_options.loginImage.localFile.childImageSharp.gatsbyImageData} />
                </div>
                <div className="right">
                  <button  aria-label="Close Popup" className="close" onClick={this.toggleModal}><CgClose /></button>
                  <h1 className="heading--l">{parse(data.wp.options.acf_options.loginHeading)}</h1>
                  <div className="text">{parse(data.wp.options.acf_options.loginText)}</div>
                  <form
                    method="post"
                    onSubmit={event => {
                      this.handleSubmit(event)
                      // navigate(`/`)
                    }}
                  >
                    <label className="email" style={{marginRight:'2rem'}}>
                      <span>Email</span>
                      <input type="text" name="email" onChange={this.handleUpdate} />
                    </label>
                    <label className="password">
                      <span>Password</span>
                      <input
                        type="password"
                        name="password"
                        onChange={this.handleUpdate}
                      />
                    </label>
                    <div className="button-wrap">
                      <ButtonForm 
                        buttonBgColor={'white'}
                        buttonBgColorHover={'brandColor'}
                        buttonTextColor={'black'}
                        buttonTextColorHover={'black'}
                        buttonBorderColor={'black'}
                        text="Login"
                        />
                      </div>
                  </form>
                </div>
              </div>
            </LoginForm>
          )}
        />
    )
  }
}

export default Login
