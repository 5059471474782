import React, { useState } from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
export default function Nav({ toggleMobMenu }) {
  const data = useStaticQuery(graphql`
    query NavQuery {
      wpCategory(name: { eq: "News" }) {
        wpChildren {
          nodes {
            name
            slug
          }
        }
      }
    }
  `)

  const [offers, setOffers] = useState(false)
  const [ariaOffers, setAriaOffers] = useState(false)
  const [ariaIndependents, setAriaIndependents] = useState(true)

  const isClient =
    typeof document !== "undefined" && typeof window !== "undefined"
  let isIndependentsArchive

  if (isClient) {
    isIndependentsArchive =
      window.location.pathname === "/independents" ||
      window.location.pathname === "/independents/"
        ? true
        : false
  }

  const fakeOfferButtonClick = () => {
    if (isClient) {
      let offersBtn = document.querySelector("button.offers")

      if (offers === false) {
        offersBtn.click()
      }
      setAriaOffers(true)
      setAriaIndependents(false)
    }
  }
  const fakeResetButtonClick = () => {
    if (isClient) {
      let resetBtn = document.querySelector("button.clear")
      setOffers(false)
      removeSession()
      resetBtn.click()
    }
  }

  const setSession = () => {
    setAriaOffers(true)
    setAriaIndependents(false)
    if (typeof window !== "undefined") {
      sessionStorage.setItem("showOffers", true)
    }
  }
  const removeSession = () => {
    setAriaOffers(false)
    toggleMobMenu()
    setAriaIndependents(true)
    if (typeof window !== "undefined") {
      sessionStorage.setItem("showOffers", false)
    }
  }
  return (
    <ul className="nav-lower">
      <li className="independents">
        <Link
          to={`/areas/${process.env.GATSBY_SUBDOMAIN}`}
          onClick={removeSession}
          className="independents"
          state={{ offers: offers }}
        >
          Independents Map
        </Link>
      </li>

      <li className="articles has-sub-menu">
        <Link onClick={removeSession} to="/news">
          News
        </Link>
        <ul className="sub-menu">
          {data.wpCategory.wpChildren.nodes.map((node, index) => {
            return (
              <li key={index}>
                <Link onClick={removeSession} to={`/${node.slug}`}>
                  {node.name}
                </Link>
              </li>
            )
          })}
        </ul>
      </li>
      <li className="articles">
        <Link onClick={removeSession} to="/food-and-drink">
          Food &amp; Drink
        </Link>
      </li>
      <li className="articles">
        <Link onClick={removeSession} to="/entertainment-and-culture">
          Entertainment &amp; Culture
        </Link>
      </li>
      <li className="articles">
        <Link onClick={removeSession} to="/shopping-and-lifestyle">
          Shopping &amp; Lifestyle
        </Link>
      </li>
      <li className="footer-only">
        <Link onClick={removeSession} to="/terms-and-conditions">
          Terms &amp; Conditions
        </Link>
      </li>
      <li className="footer-only">
        <Link onClick={removeSession} to="/privacy-policy">
          Privacy Policy
        </Link>
      </li>
    </ul>
    // <ul className="nav-lower">
    //     <li>
    //         {isIndependentsArchive
    //         ? <div className={`offers-${ariaIndependents} button-wrap independents`}>
    //             <button onClick={fakeResetButtonClick}>Independents</button>
    //           </div>
    //         : <AniLink
    //             cover
    //             bg={'var(--brandColor)'}
    //             direction={'left'}
    //             duration={1.5}
    //             to="/independents" onClick={removeSession} state={{offers:offers}}>Independents</AniLink>
    //         }
    //     </li>
    //     <li>
    //         {isIndependentsArchive
    //         ?
    //         <div className={`offers-${ariaOffers} button-wrap offers`}>
    //             <button onClick={fakeOfferButtonClick}>Offers</button>
    //           </div>
    //         : <AniLink
    //             cover
    //             bg={'var(--brandColor)'}
    //             direction={'left'}
    //             duration={1.5}
    //             to="/independents" onClick={setSession} state={{offers:!offers}}>Offers</AniLink>
    //         }
    //     </li>
    //     <li>
    //         <AniLink
    //         cover
    //         bg={'var(--brandColor)'}
    //         direction={'left'}
    //         duration={1.5}
    //         onClick={removeSession}
    //         to="/food-and-drink">Food &amp; Drink</AniLink>
    //     </li>
    //     <li>
    //         <AniLink
    //         cover
    //         bg={'var(--brandColor)'}
    //         direction={'left'}
    //         duration={1.5}
    //         onClick={removeSession} to="/entertainment-and-culture">Entertainment &amp; Culture</AniLink>
    //     </li>
    //     <li>
    //         <AniLink
    //         cover
    //         bg={'var(--brandColor)'}
    //         direction={'left'}
    //         duration={1.5}
    //         onClick={removeSession} to="/shopping-and-lifestyle">Shopping &amp; Lifestyle</AniLink>
    //     </li>
    // </ul>
  )
}
