import { createGlobalStyle } from 'styled-components'


export const ModalStyle = createGlobalStyle`

    .modal{
    position:fixed;
    top:0;
    left:0;
    height:100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow:scroll;
    width:100vw;
    display:flex;
    justify-content:center;
    align-items:center;
    z-index:1000000000;
    transition:0.5s all ease-in-out;
    background:rgba(0,0,0,.8);



        .centered{
        position: relative;
        min-height:40vh;
        transition:0.5s all ease-in-out;
        display:block;
        background:var(--white);
        width:calc(100% - 4rem);
        margin:2rem 0;

        @media only screen and (min-width: 1024px) {
        width:70vw;
        }

            .left{
            position: absolute;
            display:block;
            width:100%;
            height:100%;
            opacity:0;

                @media only screen and (min-width: 1024px) {
                width:30%;
                height:100%;
                opacity:1;
                }

                .gatsby-image-wrapper{
                width:100% !important;
                height:100% !important;
                position:absolute !important;
                top:0 !important;
                left:0 !important;

                    img{
                    width:100% !important;
                    height:100% !important;
                    position:absolute !important;
                    top:0 !important;
                    left:0 !important;
                    object-fit:cover;
                    }

                }
            }

            .right{
            width:100%;
            padding:2rem;
            position:relative;
            background:var(--white);

            
                @media only screen and (min-width: 1024px) {
                width:70%;
                height:100%;
                padding:4rem;
                margin-left:30%;
                }

                .close{
                position:absolute;
                top:2rem;
                right:2rem;
                cursor:pointer;

                    svg{
                    transition:0.5s all ease-in-out;        
                    }

                    &:hover svg{
                    transform:rotate(90deg);
                    }
                }
          

                .text{
                margin-bottom:2rem;
                font-size:0.9rem;
                    p{
                    line-height:1.5rem;
                    margin-bottom:1rem;

                        &:last-child{
                            margin-bottom:0;
                        }

                    }
                }

                form{

                    .listing,
                    .phone,
                    .business,
                    .email,
                    .name,
                    .message,
                    .password{
                    width:100%;
                    display:inline-block;
                    margin-bottom:1rem;

                        @media only screen and (min-width: 650px) {
                        width:calc(50% - 1rem);    
                        }

                        span{
                        width:100%;
                        margin-bottom:0.5rem;
                        position:relative;
                        font-size:0.9rem;
                        display:block;
                        }

                        textarea,
                        input{
                        width:100%;
                        position:relative;
                        display:block;
                        border:1px solid rgba(0,0,0,0.8);
                        padding:0.5rem;
                        transition:0.5s all ease-in-out;

                            &:focus{
                            outline:none;
                            border:1px solid var(--brandColor);
                            }

                        }
                    }
                }


                .text,
                form,
                .heading--l{
                    width:100%;
                }

                .button-wrap{
                text-align:right;
                }
            }

        }
        
        &.overflow-required{
        align-items:flex-start;

            .centered{
                .right{
                height:100%;
                }
            }

        }
    }

`