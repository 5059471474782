import React, { useState } from "react"
import styled from "styled-components"
import Header from "./global/Header"
import Footer from "./global/Footer"
import SignupModal from "./global/SignupModal"
import { GlobalStyle } from "../styles/global-styles"
import { ModalStyle } from "../styles/modal-styles"
import { Typography } from "../styles/typography"

const ContentWrapper = styled.div`
  position: relative;
  margin-top: var(--headerHeight);
  z-index: 0;
  background: var(--white);
  transition: 0.5s transform ease-in-out, 0.75s 0.5s opacity ease-in-out;
  transform: translateX(${props => props.translateContent});
  overflow: ${props => props.citySwitcherActiveOverflow};

  &:before {
    height: var(--headerHeight);

    content: "";
    width: 100vw;
    position: absolute;
    top: calc(0 - var(--headerHeight));
    left: 0;
  }
`
const Fader = styled.div`
  &:before {
    content: "";
    width: 100vw;
    transition: 0.5s all ease-in-out;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    position: fixed;
    pointer-events: none;
    z-index: 100000000;
    left: 0;
    top: 0;
    background: var(--black);
    transform: translateX(${props => props.translateContent});
    opacity: ${props => (props.isToggled === true ? "0.6" : "0")};
  }
`

export default function Layout({ children }) {
  const [translateContent, setTranslateContent] = useState(0)
  const [content, setContent] = useState(false)
  const [contentMenu, setContentMenu] = useState(false)
  const [navToggle, setNavToggle] = useState(false)
  const [overflow, setOverflow] = useState("auto")
  const [isLoggedInControl, setIsLoggedInControl] = useState(false)

  const isClient =
    typeof window !== "undefined" || typeof document !== "undefined"

  if (isClient) {
    let vh

    const setVHUnits = () => {
      vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty("--vh", `${vh}px`)
    }

    setVHUnits()
    window.addEventListener("resize", setVHUnits)
  }

  const toggleIsLoggedIn = () => {
    if (isLoggedInControl === false) {
      setIsLoggedInControl(true)
    } else {
      setIsLoggedInControl(false)
    }
  }

  const toggleMobMenu = e => {
    if (isClient) {
      console.log("CLICKED")
      const isMobile = window.innerWidth < 1024

      if (isMobile) {
        setContentMenu(!contentMenu)
        setNavToggle(!navToggle)

        const mobMenu = document.getElementById("mobMenu")

        if (mobMenu.classList.contains("active")) {
          mobMenu.classList.remove("active")
        } else {
          mobMenu.classList.add("active")
        }

        // if(contentMenu === true){
        //   setTranslateContent('0');
        // }else{
        //   setTranslateContent('-320px');
        // }

        if (overflow === "auto") {
          setOverflow("hidden")
        } else {
          setOverflow("auto")
        }
      }
    }
  }

  const toggleCitySwitcher = e => {
    setContent(!content)

    if (content === true) {
      setTranslateContent("0")
    } else {
      setTranslateContent("320px")
    }

    if (overflow === "auto") {
      setOverflow("hidden")
    } else {
      setOverflow("auto")
    }
  }

  return (
    <div
      id="layout"
      onClick={content === true ? toggleCitySwitcher : function () {}}
      style={{ overflow: overflow }}
    >
      <Fader translateContent={translateContent} isToggled={contentMenu}>
        <Header
          toggleCitySwitcher={toggleCitySwitcher}
          translateContent={translateContent}
          toggleMobMenu={toggleMobMenu}
          navToggle={navToggle}
          citySwitcherIsActive={content}
          mobMenuIsActive={contentMenu}
          toggleIsLoggedIn={toggleIsLoggedIn}
          isLoggedInControl={isLoggedInControl}
          setIsLoggedInControl={setIsLoggedInControl}
        />
        <GlobalStyle />
        <ModalStyle />
        <Typography />
        <ContentWrapper
          className="mainContent"
          translateContent={translateContent}
          isToggled={contentMenu}
          // citySwitcherActive={content === true ? '30vw' : '0'}
          // mobMenuActive={contentMenu === true ? '-30vw' : '0'}
        >
          {children}
        </ContentWrapper>

        <Footer translateContent={translateContent} />
      </Fader>
      {/* <SignupModal /> */}
    </div>
  )
}
