import React, { useState } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { IoSearchSharp } from "react-icons/io5"
import { logout } from "../../services/auth"
import styled from "styled-components"

const MobNavInner = styled.div`
  .footer-only {
    display: none;
  }

  .independents_btn {
    width: 100%;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    border: 1px solid var(--brandColor);
    position: relative;
    transition: 0.5s all ease-in-out;
    cursor: pointer;
    display: block;
    text-decoration: none;
    text-align: center;
    background: var(--brandColor);
    color: var(--black);

    &:hover {
      background: var(--charcoal);
      color: var(--brandColor);

      button {
        color: var(--brandColor);
      }
    }

    button {
      border: 0;
    }
  }

  .toggleLogin {
    width: calc(100% - 4rem);
    border: 1px solid var(--brandColor);
    color: var(--brandColor);
    padding: 0.5rem 1rem;
    position: absolute;
    bottom: 2rem;
    transition: 0.5s all ease-in-out;
    cursor: pointer;
    display: block;
    text-decoration: none;
    text-align: center;

    &:hover {
      background: var(--brandColor);
      color: var(--black);
    }
  }

  .searchToggle {
    position: absolute;
    top: 2rem;
    left: 2rem;
    color: var(--brandColor);
    font-size: 1.8rem;
  }

  ul.nav-lower {
    margin-top: 63px;
    border-top: 1px solid var(--brandColor);
    padding-top: 2rem;
    padding-bottom: 2rem;

    &.nav-lower-cats {
      margin-top: 0;
    }

    /* li{
        &:nth-child(2),
        &:nth-child(5){
            margin-bottom:2rem;
        }

        &:nth-child(2){
            border-bottom:1px solid var(--brandColor);
            padding-bottom:2rem;
        }
        } */
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 0.5rem;
      position: relative;
      transition: 0.5s all ease-in-out;

      &:last-of-type {
        margin-bottom: 0;
      }

      &.articles {
        a {
          color: var(--white);
          font-size: 1rem;

          &:hover {
            color: var(--brandColor);
          }
        }
      }

      /* button, */
      a {
        color: var(--brandColor);
        text-decoration: none;
        font-size: 1.2rem;
        transition: 0.5s all ease-in-out;
        cursor: pointer;
        position: relative;
        display: block;

        svg {
          color: var(--brandColor);
          margin-top: 3px;
          top: 3px;
          position: relative;
          transition: 0.5s all ease-in-out;
        }
      }
      &:hover {
        padding-left: 1rem;

        button,
        a {
          color: var(--white);
        }
      }
    }
  }

  .nav-upper {
    border-top: 1px solid var(--brandColor);
    padding-top: 2rem;
    border-bottom: 1px solid var(--brandColor);
    padding-bottom: 2rem;

    li {
      button,
      a {
        font-size: 0.9rem;
        color: var(--white);

        &:hover {
          color: var(--brandColor);
        }
      }
    }
  }
`

export default function MobNav({
  toggleShowSearch,
  toggleMobMenu,
  isLoggedInControl,
  toggleIsLoggedIn,
  toggleLoginModal,
}) {
  const data = useStaticQuery(graphql`
    query MobNavQuery {
      wpCategory(name: { eq: "News" }) {
        wpChildren {
          nodes {
            name
            slug
          }
        }
      }
    }
  `)
  const [offers, setOffers] = useState(false)
  const [ariaOffers, setAriaOffers] = useState(false)
  const [ariaIndependents, setAriaIndependents] = useState(true)

  const isClient =
    typeof document !== "undefined" && typeof window !== "undefined"
  let isIndependentsArchive

  if (isClient) {
    isIndependentsArchive =
      window.location.pathname === "/independents" ||
      window.location.pathname === "/independents/"
        ? true
        : false
  }

  const fakeOfferButtonClick = () => {
    if (isClient) {
      let offersBtn = document.querySelector("button.offers")

      if (offers === false) {
        offersBtn.click()
      }
      setAriaOffers(true)
      setAriaIndependents(false)
    }
  }
  const fakeResetButtonClick = () => {
    if (isClient) {
      let resetBtn = document.querySelector("button.clear")
      setOffers(false)
      removeSession()
      resetBtn.click()
    }
  }

  const setSession = () => {
    setAriaOffers(true)
    setAriaIndependents(false)
    if (typeof window !== "undefined") {
      sessionStorage.setItem("showOffers", true)
    }
  }
  const removeSession = () => {
    setAriaOffers(false)
    toggleMobMenu()
    setAriaIndependents(true)
    if (typeof window !== "undefined") {
      sessionStorage.setItem("showOffers", false)
    }
  }
  return (
    <MobNavInner>
      <button
        onClick={toggleShowSearch}
        className="searchToggle"
        id="searchToggle"
      >
        <IoSearchSharp className="SearchIcon" />
      </button>

      <ul className="nav-lower nav-lower-news">
        <li className="articles">
          <Link onClick={removeSession} to="/news">
            News
          </Link>
        </li>

        {data.wpCategory.wpChildren.nodes.map((node, index) => {
          return (
            <li className="articles" key={index}>
              <Link onClick={removeSession} to={`/${node.slug}`}>
                {node.name}
              </Link>
            </li>
          )
        })}
      </ul>
      <ul className="nav-lower nav-lower-cats">
        <li className="articles">
          <Link onClick={removeSession} to="/food-and-drink">
            Food &amp; Drink
          </Link>
        </li>
        <li className="articles">
          <Link onClick={removeSession} to="/entertainment-and-culture">
            Entertainment &amp; Culture
          </Link>
        </li>
        <li className="articles">
          <Link onClick={removeSession} to="/shopping-and-lifestyle">
            Shopping &amp; Lifestyle
          </Link>
        </li>
      </ul>
      <nav>
        <ul className="nav-upper">
          <li>
            <Link to="/advertise" onClick={toggleMobMenu}>
              Advertise
            </Link>
          </li>
        </ul>
      </nav>

      {isLoggedInControl === true ? (
        <button
          className="toggleLogin"
          onClick={event => {
            logout(() => toggleIsLoggedIn())
          }}
        >
          Logout
        </button>
      ) : (
        <button className="toggleLogin" onClick={toggleLoginModal}>
          Login
        </button>
      )}

      <Link
        to={`/areas/${process.env.GATSBY_SUBDOMAIN}`}
        className="independents_btn"
      >
        Independents Map
      </Link>
    </MobNavInner>
  )
}
