import algoliasearch from "algoliasearch/lite"
import { createRef, default as React, useState } from "react"
import { InstantSearch } from "react-instantsearch-dom"
import { ThemeProvider } from "styled-components"
import DebouncedSearchBox from "./styled-search-box"
import StyledSearchResult from "./styled-search-result"
import StyledSearchRoot from "./styled-search-root"
import useClickOutside from "./use-click-outside"

const theme = {
  foreground: "#050505",
  background: "white",
  faded: "#888",
}

export default function Search({
  indices,
  show,
  toggleShowSearch,
  navToggleActive,
}) {
  const rootRef = createRef()
  const [query, setQuery] = useState()
  const [hasFocus, setFocus] = useState(false)
  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
  )

  const isActive = () => {
    if (show === true) {
      toggleShowSearch(false)
    }
  }

  useClickOutside(rootRef, () => {
    setFocus(false)
    isActive()
  })

  return (
    <ThemeProvider theme={theme}>
      <StyledSearchRoot
        id="search"
        ref={rootRef}
        show={show}
        navToggleActive={navToggleActive}
      >
        <InstantSearch
          searchClient={searchClient}
          indexName={indices[0].name}
          onSearchStateChange={({ query }) => {
            setQuery(query)
          }}
        >
          <DebouncedSearchBox delay={1000} setFocus={setFocus} />
          <StyledSearchResult
            show={query && query.length > 0}
            indices={hasFocus ? indices : []}
            showMessage={!hasFocus}
          />
        </InstantSearch>
      </StyledSearchRoot>
    </ThemeProvider>
  )
}
