import styled from "styled-components"

export default styled.div`
  height: 100%;
  max-height: 100%;
  position: fixed;
  z-index: 2000;
  right: 0;
  top: 0;
  display: block;
  width: 320px;
  padding: 0;
  border-radius: 0px;
  background: var(--charcoal);
  color: var(--white);
  transition: 0.5s all ease-in-out;
  transform: translateX(100%);
  /* transform:${props =>
    props.show === true && props.navToggleActive === false
      ? `translateY(var(--headerHeightSetter)) translateX(0)`
      : props.show === false && props.navToggleActive === false
      ? `translateY(var(--headerHeightSetter)) translateX(100%)`
      : props.show === true && props.navToggleActive === true
      ? `translateY(0) translateX(0)`
      : props.show === false && props.navToggleActive === true
      ? `translateY(0) translateX(100%)`
      : " "}; */

  &.active {
    transform: translateX(0);
    z-index: 100000000000;
  }

  @media only screen and (min-width: 1024px) {
    width: 40vw;
    max-height: calc(100vh - var(--headerHeightSetter));
    max-height: calc((var(--vh, 1vh) * 100) - var(--headerHeightSetter));
  }

  .instructionMessage {
    padding: 2rem;
    font-size: 1.3rem;
    font-family: var(--font-bold);
    color: var(--brandColor);
  }
`
