import React, { useRef, useState, useEffect } from "react"
import Login from "../login"
import styled from "styled-components"
import LogoMark from "../../assets/logomark.inline.svg"
import LogoType from "../../assets/logotype.inline.svg"
import Nav from "./Nav"
import MobNav from "./MobNav"
import Arrow from "../../assets/site-router-arrow.inline.svg"
import { Link } from "gatsby"
import { logout } from "../../services/auth"
import Search from "../search"
import { IoSearchSharp } from "react-icons/io5"
import YorkImg from "../../images/City_York.jpeg"
import LeedsImg from "../../images/City_Leeds.jpeg"
import ManchesterImg from "../../images/City_Manchester.jpeg"

const searchIndices = [
  {
    name: "Posts index: (" + process.env.GATSBY_SITENAME + ")",
    title: "Posts index: (" + process.env.GATSBY_SITENAME + ")",
  },
  {
    name: "Listings index: (" + process.env.GATSBY_SITENAME + ")",
    title: "Listings index: (" + process.env.GATSBY_SITENAME + ")",
  },
  {
    name: "Imported Listings index: (" + process.env.GATSBY_SITENAME + ")",
    title: "Imported Listings index: (" + process.env.GATSBY_SITENAME + ")",
  },
]

const MobMenu = styled.div`
  width: 320px;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  z-index: 0;
  top: 0;
  right: 0;
  background: var(--charcoal);
  color: var(--brandColor);
  transition: 0.5s transform ease-in-out !important;
  padding: 2rem;
  z-index: 999999999;
  /* transform: translateX(${props => props.citySwitcherIsActive}); */
  transform: translateX(100%);

  &.active {
    transform: translateX(0%);
  }
`

const NavToggle = styled.div`
  align-items: center;
  position: fixed;
  top: 0;
  right: 2rem;
  width: 30px;
  height: var(--headerHeightSetter);
  transition: 0.5s all ease-in-out;
  z-index: 9999999999;
  /* z-index: 2000; */
  display: flex;
  transform: translateY(0) translateX(${props => props.translateContent});

  &.active {
    .navToggleInner {
      .line {
        background: var(--brandColor);
        width: 21px;
      }

      .line.middle {
        opacity: 0;
      }

      .line.top {
        transform: rotate(45deg);
      }

      .line.bottom {
        transform: rotate(-45deg);
      }
    }
  }

  .navToggleInner {
    width: 30px;
    height: 17px;
    position: relative;
    cursor: pointer;

    .line {
      width: 30px;
      height: 1px;
      background: var(--black);
      position: absolute;
      transition: 0.5s all ease-in-out;

      &.top {
        top: 0;
        transform-origin: top left;
      }
      &.middle {
        top: 7px;
      }
      &.bottom {
        top: 14px;
        transform-origin: bottom left;
      }
    }

    &:hover {
      .line {
        background: var(--brandColor);
      }
    }

    @media only screen and (min-width: 1024px) {
      display: none;
    }
  }
`

const MinimalHeader = styled.div`
  /* display:none !important; */
`
const Header = styled.header`
  /* display:none !important; */
`
const HeaderWrapper = styled.div`
  .header_area_button {
    position: fixed;
    width: 100%;
    padding: 1rem 2rem;
    text-align: center;
    background: var(--brandColor);
    color: inherit;
    left: 0;
    top: 100%;
    z-index: 10;
    text-decoration: none;
    transition: 0.5s all ease-in-out !important;
    display: block;

    @media only screen and (min-width: 1024px) {
      display: none;
    }

    &:hover {
      background: var(--black);
      color: var(--white);
    }
  }

  #minimalHeader,
  header {
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 100;
    transform: translateY(0%);
    transition: 0.5s transform ease-in-out;
    padding: 0;
    transform: translateX(${props => props.translateContent});

    .flex {
      display: flex;
      padding: 1rem 2rem;

      @media only screen and (min-width: 1140px) {
        padding: 2rem 4rem;
      }
    }

    &.hide {
      transform: translateY(-200%)
        translateX(${props => props.translateContent});
    }

    .selector {
      margin-left: 1rem;

      .selectWrapper {
        position: relative;
      }

      .fakeSelect {
        font-family: var(--font-brand);
        font-size: 2rem;

        @media only screen and (min-width: 1140px) {
          font-size: 2.5rem;
        }

        svg {
          margin-left: 8px;
          width: 10px;
          height: 26px;
        }
      }
    }

    .logoType {
      width: 150px;

      @media only screen and (min-width: 1140px) {
        width: auto;
      }
    }

    .logoMarkWrap {
      display: flex;
      align-items: center;
    }

    .logoMark {
      width: 50px;
      height: 50px;

      @media only screen and (min-width: 1140px) {
        width: 70px;
        height: 70px;
      }

      polygon#Fill-13,
      polygon#Fill-33 {
        fill: var(--brandColor) !important;
      }
    }

    button.citySwitcher {
      /* transition:0.5s all ease-in-out; */
      cursor: pointer;
      display: flex;
      color: var(--black);

      &:hover {
        color: var(--brandColor);
      }
      &:focus {
        outline: none;
      }

      span {
        transition: 0.5s all ease-in-out;
      }

      svg {
        transition: 0.5s all ease-in-out;

        polygon {
          transition: 0.5s all ease-in-out;
        }
      }

      &.active {
        span {
          color: var(--brandColor);
        }

        .arrow-wrapper {
          animation: slide-right 0.75s cubic-bezier(0.895, 0.03, 0.685, 0.22)
            infinite alternate both;
        }

        &:hover {
          .arrow-wrapper {
            animation: slide-right 0.75s cubic-bezier(0.895, 0.03, 0.685, 0.22)
              infinite alternate both;
          }
        }

        svg {
          transform: rotate(90deg) !important;
          polygon {
            fill: var(--brandColor);
          }
        }
      }

      &:hover {
        span {
          color: var(--brandColor);
        }

        svg {
          transform: rotate(-90deg);
          polygon {
            fill: var(--brandColor);
          }
        }

        .arrow-wrapper {
          animation: slide-right 0.75s cubic-bezier(0.895, 0.03, 0.685, 0.22)
            infinite alternate both;
        }
      }

      @keyframes slide-bottom {
        0% {
          -webkit-transform: translateY(0);
          transform: translateY(0);
        }
        100% {
          -webkit-transform: translateY(10px);
          transform: translateY(10px);
        }
      }
      @keyframes slide-right {
        0% {
          -webkit-transform: translateX(0);
          transform: translateX(0);
        }
        100% {
          -webkit-transform: translateX(10px);
          transform: translateX(10px);
        }
      }
    }

    nav {
      width: 100%;
      display: none;

      // ====================================
      // MQ START
      // ====================================
      @media only screen and (min-width: 1024px) {
        display: flex;
        flex-wrap: wrap;

        .containsSeachButtons,
        .nav-upper {
          font-family: var(--font-bold);

          .toggleLogin {
            border: 1px solid var(--black);
            background: var(--white);
            padding: 5px 15px;
            transition: 0.5s all ease-in-out;
            cursor: pointer;

            &:hover {
              color: var(--black);
              background: var(--brandColor);
            }
          }
        }

        .nav-lower {
          font-family: var(--font-reg);

          .footer-only {
            display: none;
          }
        }

        .containsSeachButtons,
        .nav-upper,
        .nav-lower {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: 0;
          list-style: none;

          li {
            margin-left: 1rem;
            height: 100%;
            display: flex;
            align-items: center;

            button {
              background: none;
              border: 0;
              cursor: pointer;
            }

            .button-wrap,
            a {
              color: var(--black);
              text-decoration: none;
              position: relative;
              display: block;
              padding-bottom: 5px;
              padding-top: 1px;
              margin-top: 5px;
              overflow: hidden;

              &:after {
                width: 100%;
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                background: var(--black);
                transform: translateX(-101%);
                transition: 0.5s all ease-in-out;
              }

              &[aria-current="page"],
              &.offers-true,
              &:hover {
                &:after {
                  transform: translateX(0);
                }
              }
            }
            ul {
              position: absolute;
              bottom: 0;
              list-style: none;
              visibility: hidden;
              opacity: 0;
              transform: translateY(90px);
              transition: all 0.5s ease-in-out;
              background: var(--black);
              border: 1px solid var(--black);
              border-radius: 3px;
              margin-left: -10px;

              &:before {
                width: 0px;
                height: 0px;
                border-style: solid;
                border-width: 0 10px 10px 10px;
                border-color: transparent transparent var(--black) transparent;
                content: "";
                display: block;
                position: absolute;
                top: -10px;
                left: 10px;
              }

              li {
                margin-left: 0;
                margin-bottom: 0;
                transition: all 0.5s ease-in-out;

                &:last-child {
                  border-bottom: 0;
                }

                a {
                  transition: all 0.5s ease-in-out;
                  padding: 10px;

                  color: white;
                  display: block;
                  position: relative;
                  &:after {
                    display: none;
                  }
                }
              }
            }
            &.has-sub-menu:hover {
              ul {
                visibility: visible;
                opacity: 1;
                transform: translateY(60px);

                li {
                  &:hover {
                    background: var(--brandColor);

                    a {
                      color: var(--black);
                    }
                  }
                }
              }
            }
          }

          .independents,
          .offers {
            button {
              background: var(--brandColor);
              color: var(--black) !important;
              border: 1px solid var(--black) !important;
              padding: 6px 10px;
              transition: 0.5s all ease-in-out;

              &:hover {
                background: var(--black);
                color: var(--white) !important;
              }
            }

            &.offers-true {
              button {
                background: var(--black);
                color: var(--white) !important;
              }
            }

            &:after {
              display: none;
            }
          }
          a.independents,
          a.offers {
            background: var(--brandColor);
            color: var(--black) !important;
            border: 1px solid var(--black) !important;
            padding: 6px 10px;
            transition: 0.5s all ease-in-out;

            &:hover {
              background: var(--black);
              color: var(--white) !important;
            }

            &:after {
              display: none;
            }
          }
        }

        .nav-upper li a:after {
          height: 2px;
        }
        .nav-lower li a:after,
        .nav-lower li .button-wrap:after {
          height: 1px;
        }
      }
      // ====================================
      // MQ END
      // ====================================
    }
  }

  #minimalHeader {
    transform: translateY(-200%) translateX(${props => props.translateContent});
    position: fixed;
    top: 0;
    left: 0;

    .flex {
      display: flex;
      padding: 0.5rem 2rem;

      @media only screen and (min-width: 1140px) {
        padding: 0.5rem 4rem;
      }
    }

    .logo-wrapper {
      display: flex;
      align-items: center;

      a {
        text-decoration: none;
        color: inherit;
      }

      .fakeSelect {
        min-width: 115px;
        display: flex;

        button {
          font-size: 1.6rem;
        }
      }
    }
    &.show {
      transform: translateY(0%) translateX(${props => props.translateContent});
    }

    .logoMark {
      width: 40px;
      height: 40px;

      polygon#Fill-13,
      polygon#Fill-33 {
        fill: var(--brandColor) !important;
      }
    }

    nav {
      flex-wrap: nowrap;
      justify-content: flex-end;

      ul {
        width: auto !important;
      }
    }
  }
`

const FakeHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  max-height: var(--headerHeight);
  background: var(--white);
  display: block;
  z-index: 20;
  transition: 0.5s transform ease-in-out, 0.5s max-height ease-in-out;
  transform: translateX(${props => props.translateContent});
`

const CitySwitcher = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 0;
  width: 320px;
  background: var(--brandColor);
  pointer-events: none;
  color: var(--black);
  transition: 0.5s transform ease-in-out;

  &.hidden {
    transform: translateX(-100%);
    /* transition:0.5s opacity ease-in-out; */
  }

  &.active {
    pointer-events: all;
  }

  .heading-wrapper {
    height: var(--headerHeightSetter);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s all ease-in-out;

    h3 {
      font-family: var(--font-heading);
      text-align: center;
    }
  }

  .cities {
    width: 100%;
    height: calc(100vh - var(--headerHeightSetter));
    height: calc((var(--vh, 1vh) * 100) - var(--headerHeightSetter));
    transition: 0.5s all ease-in-out;
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;

    a {
      width: 100%;
      height: 100%;
      position: relative;
      display: block;
      text-decoration: none;
      text-transform: uppercase;
      transition: 0.5s all ease-in-out;
      transform-origin: left;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      span {
        z-index: 10;
        position: relative;
        color: var(--brandColor);
        font-family: var(--font-heading);
        font-size: 4rem;
        transition: 0.5s all ease-in-out;
        text-shadow: 3px 3px 0px rgba(255, 255, 255, 1);
      }

      img {
        width: 100%;
        height: 104%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        z-index: 0;
        opacity: 0.8;
        transition: 0.5s all ease-in-out;
        filter: grayscale(1);
      }

      .tint {
        width: 100%;
        height: 104%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background: rgba(0, 0, 0, 1);
        transition: 0.5s all ease-in-out;
        opacity: 0.5;
        mix-blend-mode: multiply;
      }

      &:hover {
        img {
          opacity: 0.9;
          transform: scale(1.2);
        }

        span {
          text-shadow: 3px 3px 0px rgba(255, 255, 255, 1);
        }

        &.leeds {
          .tint {
            background: var(--yellow);
            opacity: 0.8;
          }

          span {
            color: var(--yellow);
            transform: scale(1.4);
          }
        }

        &.york {
          .tint {
            background: var(--orange);
          }

          span {
            color: var(--orange);
            transform: scale(1.4);
          }
        }

        &.manchester {
          .tint {
            background: var(--red);
          }

          span {
            color: var(--red);
            transform: scale(1.4);
          }
        }
      }
    }
  }
`

const BGCover = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: var(--white);
  z-index: 0;
  transition: 0.5s transform ease-in-out;
  transform: translateY(0) translateX(${props => props.translateContent});
`

export default function NavBar({
  options,
  mobMenuIsActive,
  toggleCitySwitcher,
  translateContent,
  toggleMobMenu,
  navToggle,
  citySwitcherIsActive,
  toggleIsLoggedIn,
  isLoggedInControl,
  setIsLoggedInControl,
}) {
  const [loginModal, setLoginModal] = useState(false)
  const [showSearch, setShowSearch] = useState(false)

  const toggleShowSearch = () => {
    const search = document.querySelector("#search")
    if (showSearch === false) {
      setShowSearch(true)
      search.classList.add("active")
    } else {
      setShowSearch(false)
      search.classList.remove("active")
    }

    const searchButton = document.querySelector("#searchToggle")

    if (search.classList.contains("active")) {
    } else {
    }
  }

  const toggleLoginModal = () => {
    if (loginModal === false) {
      setLoginModal(true)
    } else {
      setLoginModal(false)
    }
  }

  const cities = [
    {
      name: "Leeds",
      link: "https://leeds.independentlife.co.uk",
      // link: 'https://indylife-leeds.netlify.app/'
    },
    {
      name: "York",
      link: "https://york.independentlife.co.uk",
      // link: 'https://indylife-york.netlify.app/'
    },
  ]

  const header = useRef()
  const minimalHeader = useRef()
  const fakeHeader = useRef()
  const mobMenu = useRef()
  const areasButton = useRef()

  useEffect(() => {
    const isClient = typeof window !== "undefined"

    const HeaderInview = () => {
      if (isClient) {
        let headerH
        let minimalHeaderH
        let areasButtonH
        let root = document.documentElement
        var lastScrollTop = 0

        const resizeHeaderInit = () => {
          headerH = header.current.offsetHeight
          minimalHeaderH = minimalHeader.current.offsetHeight
          areasButtonH = areasButton.current.offsetHeight
          root.style.setProperty(
            "--headerHeight",
            headerH + areasButtonH + "px"
          )
          root.style.setProperty("--headerHeightSetter", headerH + "px")
        }

        const resizeHeaderResize = () => {
          setTimeout(
            function () {
              headerH = header.current.offsetHeight
              minimalHeaderH = minimalHeader.current.offsetHeight
              areasButtonH = areasButton.current.offsetHeight
              if (minimalHeader.current.classList.contains("show")) {
                root.style.setProperty(
                  "--headerHeight",
                  minimalHeaderH + areasButtonH + "px"
                )
                root.style.setProperty(
                  "--headerHeightSetter",
                  minimalHeaderH + "px"
                )
                fakeHeader.current.style.maxHeight = minimalHeaderH + "px"
              } else {
                root.style.setProperty(
                  "--headerHeight",
                  headerH + areasButtonH + "px"
                )
                root.style.setProperty(
                  "--headerHeightSetter",
                  headerH + areasButtonH + "px"
                )
                fakeHeader.current.style.maxHeight = headerH + "px"
              }
            }.bind(this),
            400
          )
        }

        const resizeHeaderScroll = () => {
          var scrollTop =
            window.pageYOffset ||
            (
              document.documentElement ||
              document.body.parentNode ||
              document.body
            ).scrollTop
          if (header.current) {
            if (scrollTop > lastScrollTop && scrollTop > headerH) {
              header.current.classList.add("hide")
              minimalHeader.current.classList.add("show")
              root.style.setProperty(
                "--headerHeightSetter",
                minimalHeaderH + "px"
              )
              fakeHeader.current.style.maxHeight = minimalHeaderH + "px"
            } else {
              header.current.classList.remove("hide")
              minimalHeader.current.classList.remove("show")
              root.style.setProperty("--headerHeightSetter", headerH + "px")
              fakeHeader.current.style.maxHeight = headerH + "px"
            }
            lastScrollTop = scrollTop <= 0 ? 0 : scrollTop
          }
        }

        window.addEventListener("scroll", resizeHeaderScroll)
        window.addEventListener("resize", resizeHeaderResize)
        resizeHeaderInit()
      }
    }

    HeaderInview()
  }, [])

  return (
    <HeaderWrapper translateContent={translateContent}>
      <NavToggle
        onClick={toggleMobMenu}
        translateContent={navToggle === false ? translateContent : ""}
        className={navToggle === true ? "active" : ""}
      >
        <div className="navToggleInner">
          <div className="line top"></div>
          <div className="line middle"></div>
          <div className="line bottom"></div>
        </div>
      </NavToggle>

      <MobMenu
        id="mobMenu"
        className="mobile-menu"
        ref={mobMenu}
        citySwitcherIsActive={citySwitcherIsActive === true ? "100%" : "0"}
        mobMenuIsActiveOpacity={mobMenuIsActive === true ? "1" : "0"}
      >
        <MobNav
          toggleMobMenu={toggleMobMenu}
          toggleShowSearch={toggleShowSearch}
          isLoggedInControl={isLoggedInControl}
          toggleIsLoggedIn={toggleIsLoggedIn}
          toggleLoginModal={toggleLoginModal}
        />
      </MobMenu>

      <Login
        isLoggedIn={isLoggedInControl}
        toggleLoginModal={toggleLoginModal}
        toggleIsLoggedIn={toggleIsLoggedIn}
        isActive={loginModal}
      />

      <Search
        indices={searchIndices}
        show={showSearch}
        toggleShowSearch={toggleShowSearch}
        navToggleActive={navToggle}
      />

      <CitySwitcher
        id="citySwitcherWrapper"
        className={
          citySwitcherIsActive === true
            ? "active"
            : mobMenuIsActive === true
            ? "hidden"
            : ""
        }
        citySwitcherIsActiveOpacity={citySwitcherIsActive === true ? "1" : "0"}
      >
        <div className="heading-wrapper">
          <h3 className="heading--m">Choose your city</h3>
        </div>
        <div className="cities">
          {cities.map((city, index) => {
            return city.name !== process.env.GATSBY_SITENAME ? (
              <a
                href={city.link}
                key={city.link + index}
                className={city.name.toLowerCase()}
              >
                <span>{city.name}</span>
                <div className="tint"></div>
                <img
                  src={
                    city.name === "York"
                      ? YorkImg
                      : city.name === "Leeds"
                      ? LeedsImg
                      : city.name === "Manchester"
                      ? ManchesterImg
                      : ""
                  }
                  alt={
                    city.name === "York"
                      ? "York"
                      : city.name === "Leeds"
                      ? "Leeds"
                      : city.name === "Manchester"
                      ? "Manchester"
                      : ""
                  }
                />
              </a>
            ) : (
              ""
            )
          })}
        </div>
      </CitySwitcher>
      <BGCover translateContent={translateContent} />
      <FakeHeader
        translateContent={translateContent}
        ref={fakeHeader}
      ></FakeHeader>

      <Header ref={header} id="header">
        <div className="flex">
          <Link className="logoMarkWrap" to="/">
            <LogoMark className="logoMark" />
          </Link>
          <div className="selector">
            <div className="selectWrapper">
              <div className="fakeSelect">
                <button
                  onClick={toggleCitySwitcher}
                  className={
                    citySwitcherIsActive === true
                      ? "citySwitcher active"
                      : "citySwitcher"
                  }
                >
                  <span>{process.env.GATSBY_SITENAME.toLowerCase()}</span>
                  <div className="arrow-wrapper">
                    <Arrow />
                  </div>
                </button>
              </div>
            </div>
            <LogoType className="logoType" />
          </div>
          <nav>
            <ul className="nav-upper">
              {/* <li>
                <Link to="/offers" onClick={toggleMobMenu}>
                  Offers
                </Link>
              </li> */}
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="/advertise">Advertise</Link>
              </li>
              <li>
                <button onClick={toggleShowSearch}>
                  <IoSearchSharp className="SearchIcon" />
                </button>
              </li>
              <li>
                {isLoggedInControl === true ? (
                  <button
                    className="toggleLogin"
                    onClick={event => {
                      logout(() => toggleIsLoggedIn())
                    }}
                  >
                    Logout
                  </button>
                ) : (
                  <button className="toggleLogin" onClick={toggleLoginModal}>
                    Login
                  </button>
                )}
              </li>
            </ul>
            <Nav toggleMobMenu={toggleMobMenu} />
          </nav>
        </div>
        <Link
          to={`/areas/${process.env.GATSBY_SUBDOMAIN}`}
          className="header_area_button"
          ref={areasButton}
        >
          Independents Map
        </Link>
      </Header>

      <MinimalHeader id="minimalHeader" ref={minimalHeader}>
        <div className="flex">
          <div className="logo-wrapper">
            <Link to="/">
              <LogoMark className="logoMark" />
            </Link>
            <div className="selector">
              <div className="selectWrapper">
                <div className="fakeSelect">
                  <button
                    onClick={toggleCitySwitcher}
                    className={
                      citySwitcherIsActive === true
                        ? "citySwitcher active"
                        : "citySwitcher"
                    }
                  >
                    <span>{process.env.GATSBY_SITENAME.toLowerCase()}</span>
                    <div className="arrow-wrapper">
                      <Arrow />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <nav>
            <Nav toggleMobMenu={toggleMobMenu} />
            <ul className="containsSeachButtons">
              <li>
                <button onClick={toggleShowSearch}>
                  <IoSearchSharp className="SearchIcon" />
                </button>
              </li>
              <li>
                {isLoggedInControl === true ? (
                  <button
                    className="toggleLogin"
                    onClick={event => {
                      logout(() => toggleIsLoggedIn())
                    }}
                  >
                    Logout
                  </button>
                ) : (
                  <button className="toggleLogin" onClick={toggleLoginModal}>
                    Login
                  </button>
                )}
              </li>
            </ul>
          </nav>
        </div>
        <Link
          to={`/areas/${process.env.GATSBY_SUBDOMAIN}`}
          className="header_area_button"
        >
          Independents Map
        </Link>
      </MinimalHeader>
    </HeaderWrapper>
  )
}
