import firebase from "gatsby-plugin-firebase"

export const isBrowser = () => typeof window !== "undefined"

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("gatsbyUser")
    ? JSON.parse(window.localStorage.getItem("gatsbyUser"))
    : {}

const setUser = user => window.localStorage.setItem("gatsbyUser", JSON.stringify(user))

export const handleLogin = ({ email, password }) => {
  if (email && password ) {
    return setUser({
      email: email,
    })
  }

  return false
}

export const isLoggedIn = () => {
  const user = getUser()

  return !!user.email
}

export const logout = (callback) => {
  window.localStorage.removeItem("gatsbyUser");
  callback()
  firebase.auth().signOut()
}